const currentDate = new Date();
const months = 3;
const offsetMonthsAgo = new Date().setMonth(currentDate.getMonth() - months);

export const isNewGame = (game) => {
	const releaseDate = new Date(game.ReleaseDate);
	return releaseDate >= offsetMonthsAgo && releaseDate <= currentDate;
};

export const filterNewGames = (games) => {
	return games.filter((game) => {
		return isNewGame(game);
	});
};
