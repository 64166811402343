export default {
	resetFilters: 'Restablecer filtros',
	noSearchResults: 'Sin resultados de búsqueda',
	noGamesWithSearchEntry: 'No hay juegos con esta entrada de búsqueda.',
	showMore: 'Mostrar más',

	// Filters Game Type
	GameType: 'Tipo de juego',
	new: 'NUEVO!',
	slots: 'Slots',
	cards: 'Cartas',
	casual: 'Casual',
	dice: 'Dados',
	roulette: 'Ruleta',
	scratch: 'Rascar',
	lottery: 'Lotería',

	// Filters Game Features
	GameFeatures: 'Características',
	respins: 'Respins',
	'bonus-game': 'Juego de bonificación',
	'buy-bonus': 'Comprar bonificación',
	'buy-chance': 'Comprar oportunidad',
	'expanding-wild': 'Comodín expansivo',
	'gamble-round': 'Ronda de apuesta',
	'in-game-jackpot': 'Jackpot en el juego',
	'lines-change': 'Cambio de líneas',
	'pay-both-ways': 'Pagar en ambas direcciones',
	'provably-fair': 'Probablemente justo',
	'sticky-wilds': 'Comodines pegajosos',

	// Filters Volatility
	Volatility: 'Volatilidad',
	'volatility-low': 'Baja',
	'volatility-medium-low': 'Medio-baja',
	'volatility-medium': 'Medio',
	'volatility-medium-high': 'Medio-alto',
	'volatility-high': 'Alta',
	'volatility-very-high': 'Muy alta',

	// Filters GameMechanics
	GameMechanics: 'Mecánicas',
	Megaways: 'Megaways',
	Trueways: 'Trueways',
	'Refilling Reels': 'Rodillos recargables',
	'Cluster Pays': 'Paga en grupos',
	'Hold&Win': 'Mantén&gana',
	Crash: 'Choque',
	'Book-Of': 'Libro de',

	// Filters PlayLines
	PlayLines: 'Líneas',
	1: '1',
	5: '5',
	9: '9',
	10: '10',
	15: '15',
	20: '20',
	25: '25',
	40: '40',
	50: '50',
	100: '100',
	243: '243',
	Megaways: 'Megaways',
	'Cluster Pays': 'Paga en grupos',
	Trueways: 'Trueways',
	'Pays Anywhere': 'Paga en cualquier lugar',

	// Filters Game Theme
	all: 'Todo',
	GameTheme: 'Tema',
	adventure: 'Aventura',
	animals: 'Animales',
	asia: 'Asia',
	aztec: 'Azteca',
	beer: 'Cerveza',
	book: 'Libro',
	cars: 'Coches',
	chinese: 'Chino',
	classic: 'Clásico',
	clovers: 'Tréboles',
	cocktails: 'Cócteles',
	coins: 'Monedas',
	dice: 'Dados',
	easter: 'Pascua',
	egypt: 'Egipto',
	fantasy: 'Fantasía',
	farm: 'Granja',
	fruits: 'Frutas',
	fun: 'Diversión',
	gems: 'Gemas',
	gifts: 'Regalos',
	girls: 'Chicas',
	gold: 'Oro',
	heist: 'Robo',
	joker: 'Comodín',
	lady: 'Dama',
	magic: 'Magia',
	medieval: 'Medieval',
	money: 'Dinero',
	monster: 'Monstruo',
	music: 'Música',
	myths: 'Mitología',
	oriental: 'Oriental',
	party: 'Fiesta',
	scary: 'Aterrador',
	sea: 'Mar',
	space: 'Espacio',
	sport: 'Deportes',
	'steam-punk': 'Estilo steampunk',
	sweets: 'Dulces',
	tramp: 'Vagabundo',
	treasure: 'Tesoro',
	'wild-west': 'Oeste salvaje',
	xmas: 'Navidad',

	// Other buttons
	playDemo: 'Jugar a la demo',
	backToGames: 'REGRESAR A LOS JUEGOS',
	goUp: 'Subir',
	search: 'Buscar',
	back: 'ATRÁS',
	similarGames: 'JUEGOS SIMILARES',
	play: 'Jugar',

	allGames: 'TODOS LOS JUEGOS',
	gamification: 'GAMIFICACIÓN',
	aboutUs: 'SOBRE NOSOTROS',
	casinoBeatsTitle: 'Premios Casinobeats al desarrollador de juegos 2023',
	bestSoftwareProvider: 'Mejor proveedor de software',
	bestNewSlot: 'Mejor nueva tragamonedas',
	bgamingThumbs: 'BGAMING & THUMBS',
	bgamingThumbsText:
		'Nuestra iniciativa "Cuando el Arte se Encuentra con el Juego" da su primer gran paso con el talentoso artista Thumbs, con sede en Londres. Con su estilo distintivo inspirado en la cultura pop, ha reimaginado de manera orgánica a personajes de juegos de BGaming, como Elvis Frog, Joker Queen y Cactus Cowboy Johnny Cash, añadiendo un toque especial a nuestra experiencia en el ICE London 2023.',
	other: 'Otro',
	letStartConversation: '¡Conversemos!',
	wouldYouLikeToStartConversation:
		'¿Te gustaría iniciar una conversación con BGaming en cualquier área? ¡Escríbenos!',

	//details
	releaseDate: 'FECHA DE LANZAMIENTO',
	maxMultiplier: 'Multiplicador máximo',
	maxWin: 'Ganancia máxima',

	//drops description
	dropsTitle: 'BGaming Drops',
	dropsSubtitle:
		'BGaming Drops es una herramienta poderosa para los operadores de juegos en línea que buscan mejorar la participación y retención de los jugadores. Al ofrecer recompensas aleatorias y personalizables, BGaming Drops no solo hace que el juego sea más emocionante, sino que también proporciona a los operadores una solución de marketing flexible y eficaz. Ya sea para promociones estacionales o incentivos regulares para jugadores, BGaming Drops puede adaptarse a las necesidades únicas de cualquier plataforma de juego.',

	dropsLoyaltyTitle: 'Mayor lealtad de los jugadores',
	dropsLoyaltySubtitle:
		'Las recompensas regulares mantienen a los jugadores regresando, fomentando la lealtad y aumentando el valor de por vida.',

	dropsToolTitle: 'Herramienta de marketing flexible',
	dropsToolSubtitle:
		'Los operadores pueden adaptar las campañas de drops para alinearlas con sus objetivos promocionales y las preferencias de los jugadores.',

	dropsMotivatesTitle: 'Juego mejorado',
	dropsMotivatesSubtitle:
		'La anticipación de recibir un drop motiva a los jugadores a extender sus sesiones de juego.',

	dropsExperienceTitle: 'Experiencia del jugador mejorada',
	dropsExperienceSubtitle:
		'Los drops añaden una capa adicional de emoción y recompensa, enriqueciendo la experiencia general del juego.',

	//challenges description
	challengesTitle: 'BGaming Challenges',
	challengesSubtitle:
		'BGaming Challenges es una función innovadora diseñada para elevar la participación y retención de los jugadores al introducir una serie de desafíos emocionantes y gratificantes dentro del juego. Esta herramienta permite a los operadores crear desafíos personalizados dentro de los títulos de BGaming, proporcionando a los jugadores metas y recompensas adicionales más allá del juego estándar.',

	challengesMotivationTitle: 'Mayor motivación de los jugadores',
	challengesMotivationSubtitle:
		'Los desafíos proporcionan a los jugadores metas adicionales, motivándolos a jugar más y alcanzar hitos.',

	challengesToolTitle: 'Herramienta de marketing flexible',
	challengesToolSubtitle:
		'Los operadores pueden diseñar desafíos que se alineen con sus estrategias de marketing y las preferencias de los jugadores.',

	challengesRatesTitle: 'Mayores tasas de participación',
	challengesRatesSubtitle: 'Desafíos atractivos y diversos animan a los jugadores a pasar más tiempo en el juego.',

	challengesExperienceTitle: 'Experiencia del jugador mejorada',
	challengesExperienceSubtitle:
		'Los desafíos añaden profundidad y emoción al juego, enriqueciendo la experiencia general del jugador.',
	freeSpins: 'Giros gratis',
	giveGenerousPraiseToYour:
		'Ofrécele generosos incentivos a tus jugadores sin ningún estrés. Nuestro programa de deducción de bonificación por Giros Gratis te permitirá organizar bonos regulares para tus jugadores sin esfuerzo.',
	leverageTheFullPotential:
		'Aprovecha todo el potencial de esta herramienta simple pero poderosa para recompensar y retener a tus jugadores.',
	engage: 'Involucra',
	letThePlayersEasilyTryNewContent: 'Permite que los jugadores prueben fácilmente nuevo contenido.',
	reward: 'Recompensa',
	addWelcomePackageOrBonusProgram: 'Agrega un paquete de bienvenida o un programa de bonificación.',
	retain: 'Retén',
	freeSpinsIsAGreatTool: 'Los Giros Gratis son una excelente herramienta para la retención de jugadores.',
	gotIt: 'Entendido',
	//similar
	similarGamesTitle: 'También te podría gustar',
	randomGame: 'Juego aleatorio'
};
