import { IntlProvider, FormattedMessage } from 'react-intl';
import 'normalize.css';
import './assets/styles/App.css';
import './assets/styles/Main.css';
import './assets/styles/Gamification.css';
import './assets/styles/FreeSpinsModal.css';
import './assets/styles/ModalOverlay.css';
import { useEffect, useRef, useState } from 'react';
import DataService from './services/DataService';
import translations from './data/languages';
import MainHeader from './components/MainHeader';
import GamesExpand from './components/GamesExpand';
import GamesList from './components/GamesList';
import freespins from './assets/free_spins.png';
import bgamingdrops from './assets/bgaming_drops.png';
import brushchallenges from './assets/b_rush_challenges.png';
import conversationback from './assets/conversation_back.png';
import GamificationCard from './components/GamificationCard';
import StickyMenu from './widgets/StickyModal/StickyMenu';
import GameModal from './widgets/GameModal/GameModal';
import { useGlobalState, ACTION_TYPE, SELECTED_FEATURE } from './hooks/useGlobalState';
import FreeSpinsModal from './components/FreeSpinsModal';
import GamesFilters from './components/GamesFilters';
import { filterNewGames } from './services/DateService';

const GAMES_LENGTH = 20;

function Main() {
	const dataService = new DataService();
	const [initialData, setInitialData] = useState([]);
	const [data, setData] = useState([]);
	const [baseUrl, setBaseUrl] = useState('');
	const [category, setCategory] = useState('');
	const bannerRef = useRef(null);
	const gamesRef = useRef(null);
	const gamificationRef = useRef(null);
	const aboutRef = useRef(null);
	const [freeSpinsModal, setFreeSpinsModal] = useState(false);

	const { state, dispatch } = useGlobalState();
	const messages = translations[state.language];

	useEffect(() => {
		if (state && state.gameSrc) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [state.gameSrc]);

	useEffect(() => {
		dataService.loadData(setData, setInitialData);
	}, []);

	const updateGamesStore = () => dispatch({ type: ACTION_TYPE.SET_DATA_GAMES, payload: data });

	useEffect(() => {
		updateGamesStore();
	}, [data]);

	useEffect(() => {
		fetch('/api/getBaseUrl')
			.then((response) => response.json())
			.then((data) => {
				console.log(data, ' DATA');
				setBaseUrl(data.baseUrl);
			})
			.catch(() => console.log('Failed fetch: No base url found'));
	}, []);

	const handlePlayLink = (gameId, currency = 'FUN') => {
		if (window && window.location && window.location.hostname === 'localhost') {
			return `https://bgaming-network.com/play/${gameId}/FUN?server=demo`;
		}

		const onlineOrigin = 'https://offline-demo.k3s-dev.bgaming-system.com';
		if (window.origin === onlineOrigin) {
			return `https://bgaming-network.com/play/${gameId}/${currency}?server=demo`;
		}

		// should be https://bgaming-network.com/s/demo/compatibility/play/${gameId}/EUR?locale=en&server_url=https://cdn.bgaming-network.com/html/
		return `${baseUrl}/s/demo/compatibility/play/${gameId}/${currency}?locale=en&server_url=https://cdn.bgaming-network.com/html/`;
	};

	const setCategoryWithScroll = (item) => {
		gamesRef.current?.scrollIntoView();
		setCategory(item);
	};

	const chooseChallengesFeature = () => {
		const challengesID = 'WildWestTrueways';
		const gameUrl = handlePlayLink(challengesID, 'EUR');

		dispatch({ type: ACTION_TYPE.SET_GAME_SRC, payload: gameUrl });
		dispatch({ type: ACTION_TYPE.SET_GAME_ID, payload: challengesID });
		dispatch({ type: ACTION_TYPE.SELECT_FEATURE, payload: SELECTED_FEATURE.CHALLENGES });
	};

	const chooseDropsFeature = () => {
		const dropID = 'AztecClusters';
		const gameUrl = handlePlayLink(dropID, 'EUR');

		dispatch({ type: ACTION_TYPE.SET_GAME_SRC, payload: gameUrl });
		dispatch({ type: ACTION_TYPE.SET_GAME_ID, payload: dropID });
		dispatch({ type: ACTION_TYPE.SELECT_FEATURE, payload: SELECTED_FEATURE.DROP });
	};

	const chooseFreespinFeature = () => {
		setFreeSpinsModal(true);
	};

	return (
		<IntlProvider locale={state.language} messages={messages}>
			<div className="App" ref={bannerRef}>
				{/*{activeGameFilters && (*/}
				{/*	<GamesFilters*/}
				{/*		handlePlayLink={handlePlayLink}*/}
				{/*		locale={state.language}*/}
				{/*		setActiveGameFilters={setActiveGameFilters}*/}
				{/*	/>*/}
				{/*)}*/}

				<div className="main-wrapper">
					<MainHeader
						locale={state.language}
						data={data}
						gamesRef={gamesRef}
						gamificationRef={gamificationRef}
						aboutRef={aboutRef}
						category={category}
						setCategory={setCategory}
					></MainHeader>

					{category ? (
						<GamesExpand
							data={data}
							category={category}
							locale={state.language}
							handlePlayLink={handlePlayLink}
						/>
					) : (
						<div className="main-content">
							{/*<GamesList*/}
							{/*	games={filterNewGames(data).slice(0, GAMES_LENGTH)}*/}
							{/*	handlePlayLink={handlePlayLink}*/}
							{/*	setCategoryWithScroll={setCategoryWithScroll}*/}
							{/*	category="new"*/}
							{/*/>*/}

							<GamesList
								games={data.filter((game) => game.GameType === 'slots').slice(0, GAMES_LENGTH)}
								handlePlayLink={handlePlayLink}
								setCategoryWithScroll={setCategoryWithScroll}
								category="slots"
							/>

							<GamesList
								games={data.filter((game) => game.GameType === 'casual').slice(0, GAMES_LENGTH)}
								handlePlayLink={handlePlayLink}
								setCategoryWithScroll={setCategoryWithScroll}
								category="casual"
							/>

							<GamesList
								games={data.filter((game) => game.GameType === 'scratch').slice(0, GAMES_LENGTH)}
								handlePlayLink={handlePlayLink}
								setCategoryWithScroll={setCategoryWithScroll}
								category="scratch"
							/>

							<GamesList
								games={data
									.filter(
										(game) =>
											game.GameType !== 'slots' &&
											game.GameType !== 'casual' &&
											game.GameType !== 'scratch' &&
											game.GameType !== 'new'
									)
									.slice(0, GAMES_LENGTH)}
								handlePlayLink={handlePlayLink}
								setCategoryWithScroll={setCategoryWithScroll}
								category="other"
							/>
						</div>
					)}

					<div className="gamification" ref={gamificationRef}>
						<div className="gamification-title">
							<FormattedMessage id="gamification" />
						</div>
						<div className="gamification-container">
							<GamificationCard cardImg={freespins} title="free spins" onClick={chooseFreespinFeature} />
							<GamificationCard
								cardImg={bgamingdrops}
								title="bgaming drops"
								onClick={chooseDropsFeature}
							/>
							<GamificationCard
								cardImg={brushchallenges}
								title="b-rush challenges"
								onClick={chooseChallengesFeature}
							/>
						</div>
					</div>

					<div className="conversation" ref={aboutRef}>
						<div className="conversation-content">
							<div className="conversation-title">
								<FormattedMessage id="letStartConversation" />
							</div>
							<div className="conversation-text">
								<FormattedMessage id="wouldYouLikeToStartConversation" />
							</div>
						</div>
						<div className="conversation-img">
							<img src={conversationback} alt="" />
						</div>
					</div>
				</div>
				<StickyMenu isScrollable={true} />
				<GameModal isScrollable={false} handlePlayLink={handlePlayLink} />
				{freeSpinsModal && <FreeSpinsModal setFreeSpinsModal={setFreeSpinsModal} />}
			</div>
		</IntlProvider>
	);
}

export default Main;
